<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Lead Distribution</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <div class="row align-items-center">
              <div class="col-10">
                <h3 class="mb-0" id="leads_main_heading">Lead Distribution</h3>
              </div>
              <div class="col-2">
                <div class="text-sm">Filter Dates:</div>
                <el-select
                  v-model="selectedDate"
                  collapse-tags
                  placeholder="Select">
                  <el-option
                    v-for="(date, index) in allDates" :key="index"
                    :label="date.label"
                    :value="date.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </template>
          <div class="lead-distribution">
            <el-table
              :data="leadDistribution"
              row-key="id"
              v-loading="leadsLoading"
              header-row-class-name="thead-light"
              id="leads_table"
              :default-sort="{ prop: 'name', order: 'ascending' }"
            >
              <el-table-column
                label="Name"
                prop="name"
                min-width="100px"
                sortable
              >
                <template v-slot="{ row }">
                  {{ row.name }}
                </template>
              </el-table-column>

              <el-table-column
                label="Wedding"
                prop="wedding"
                min-width="100px"
                sortable
              >
                <template v-slot="{ row }">
                  {{ row.wedding }}
                </template>
              </el-table-column>
              <el-table-column
                label="Social"
                prop="social"
                min-width="100px"
                sortable
              >
                <template v-slot="{ row }">
                  {{ row.social }}
                </template>
              </el-table-column>
              <el-table-column
                label="Corporate"
                prop="corporate"
                min-width="100px"
                sortable
              >
                <template v-slot="{ row }">
                  {{ row.corporate }}
                </template>
              </el-table-column>
              <el-table-column
                label="Total"
                prop="total"
                width="150px"
                sortable
              >
                <template v-slot="{ row }">
                  <strong>{{ row.total }}</strong>
                </template>
              </el-table-column>
            </el-table>
            <div class="sub-total">
              <strong>{{subTotal}}</strong>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Table, TableColumn, Select, Option, Loading } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import tableMixin from "@/helpers/remoteTableMixin";
import { LeadService } from "@/services";
import moment from 'moment';

export default {
  mixins: [tableMixin],
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      leads: [],
      leadsLoading: true,
      subTotal : 0,
      selectedDate: moment().subtract(0, 'months').format('YYYY-MM')+"-01",
      leadDistribution : [],
      allDates : [],

    };
  },
  created() {
    this.fetchLeadDistribution(this.selectedDate);
    this.getLast12Months();
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdministrator"];
    },
    getLeadDistribution() {
      return this.populeteLeadDistribution();
    },
  },
  methods: {
    populeteLeadDistribution() {
      let temp = [];
      let subTotalTemp = 0;
      Object.values(this.leads).forEach(function (value) {
        temp.push({
          name: value.id !== -1 ? value.name : 'Un-Assigned',
          id: value.id !== -1 ? value.id : 0,
          wedding: value.wedding,
          social: value.social,
          corporate: value.corporate,
          total: value.total,
        });
        subTotalTemp = subTotalTemp + value.total;
      });
      this.subTotal = this.subTotal + subTotalTemp;
      this.leadDistribution = temp;
    },
    async fetchLeadDistribution(date) {
      this.leadsLoading = true;
      let leadData = await LeadService.getLeadDistribution(date, this.country);
      this.leads = leadData.data;
      this.leadsLoading = false;
      this.subTotal = 0;
      this.populeteLeadDistribution();
    },
    getLast12Months() {
      let months = [];
      for (let i = 12; i >= 0; i--) {
        months.push({
          label: moment().subtract(i, 'months').format('MMMM YYYY'),
          value: moment().subtract(i, 'months').format('YYYY-MM')+"-01",
        });
      }
      this.allDates = months;
    },
  },
  watch: {
    selectedDate(date) {
      this.fetchLeadDistribution(this.selectedDate);
    },
  },
  computed: {
    ...mapState("country/", {
      country: (state) => state.country || 'AU',
    }),
  },
};
</script>
<style>
.lead-distribution .sub-total {
  float: right;
  margin-right: 40px;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 150px;
  text-align: center;
}
</style>
